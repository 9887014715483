// Copyright 2024 Merit International Inc. All Rights Reserved

import {
  BoolPredicateOptions,
  DatePredicateOptions,
  DateTimePredicateOptions,
  DefaultPredicateOptions,
  EmailPredicateOptions,
  MAX_NUM_RULES,
  NumberPredicateOptions,
  PredicateNeedsValueSet,
  TextPredicateOptions,
} from "../constants";
import { Button, useTheme } from "@merit/frontend-components";
import { DateMask, DateTimeMask, TimeMask } from "@src/components/MaskedTextInput";
import { Log } from "@src/utils/logging";
import { RemoveModal, type RemoveModalState } from "./RemoveModal";
import { SelectField } from "./SelectField";
import { StyleSheet, View } from "react-native";
import { TextInputField } from "./TextInputField";
import { newFieldRule } from "../utils";
import { useAlertStore } from "@src/stores";
import { useFormikContext } from "formik";
import { useMemo, useState } from "react";
import { useNumRules } from "../hooks";
import { useTemplate } from "@src/api/orgportal";
import type { FC } from "react";
import type { FieldArrayRenderProps } from "formik";
import type { FieldRule, Option, Template } from "../types";
import type { GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInner as TemplateField } from "@src/gen/org-portal";

const fieldHasIdAndName = (
  field: TemplateField,
  logContext: Record<string, number | string>
): field is PickRequired<TemplateField, "fieldID" | "name"> => {
  if (field.fieldID === undefined) {
    Log.error("Template field has no fieldID", { ...logContext, fieldName: field.name });

    return false;
  }

  if (field.name === undefined) {
    Log.error("Template field has no name", { ...logContext, fieldId: field.fieldID });

    return false;
  }

  return true;
};

export type SectionFieldRuleProps = {
  readonly fieldRule: FieldRule;
  readonly fieldRuleIdx: number;
  readonly templateId: Template["id"];
  readonly templateRuleIdx: number;
  readonly showAddButton?: boolean;
  readonly disableDeleteButton?: boolean;
  readonly arr: FieldArrayRenderProps;
};

export const SectionFieldRule: FC<SectionFieldRuleProps> = ({
  arr,
  disableDeleteButton = false,
  fieldRule,
  fieldRuleIdx,
  showAddButton = false,
  templateId,
  templateRuleIdx,
}) => {
  const templateQuery = useTemplate({ templateID: templateId });
  const setAlert = useAlertStore(state => state.setAlert);
  const deleteAlert = useAlertStore(state => state.deleteAlert);
  const ctx = useFormikContext();
  const numRules = useNumRules();
  const { theme } = useTheme();
  const [removeModal, setRemoveModal] = useState<RemoveModalState>();

  const fieldOptions = useMemo(
    () =>
      templateQuery.data?.templateFields
        ?.filter(f => fieldHasIdAndName(f, { templateId }))
        .map(field => ({
          label: field.name,
          value: field.fieldID,
        })) ?? [],
    [templateQuery.data?.templateFields, templateId]
  );

  const fieldPlaceholder = useMemo(() => {
    if (templateQuery.isInitialLoading) {
      return { label: "Loading...", value: "" };
    }

    return { label: "Select field", value: "" };
  }, [templateQuery.isInitialLoading]);

  const compareOptions = useMemo(() => {
    if (!templateQuery.isFetched) {
      return [];
    }

    if (fieldRule.templateFieldId === undefined) {
      return [];
    }

    const field = templateQuery.data?.templateFields?.find(
      f => f.fieldID === fieldRule.templateFieldId
    );

    if (field === undefined) {
      Log.error("Failed to find field in template", {
        fieldId: fieldRule.templateFieldId,
        templateId,
      });
      setAlert({
        closable: true,
        id: "SectionFieldRule-compareOptions-findFieldFailure",
        onPressDelete: id => {
          deleteAlert(id);
        },
        testProps: {
          elementName: "ErrorText",
          screenName: "CreatePolicy",
        },
        text: "Something went wrong while trying to find the selected field",
        title: "Failed",
        type: "error",
      });

      return [];
    }

    switch (field.type) {
      case "Bool":
        return BoolPredicateOptions;
      case "Date":
        return DatePredicateOptions;
      case "DateTime":
        return DateTimePredicateOptions;
      case "Email":
        return EmailPredicateOptions;
      case "Text":
        return TextPredicateOptions;
      case "Number":
        return NumberPredicateOptions;
      default:
        return DefaultPredicateOptions;
    }
  }, [
    fieldRule.templateFieldId,
    templateQuery.data?.templateFields,
    templateId,
    setAlert,
    deleteAlert,
    templateQuery.isFetched,
  ]);

  const valueMask = useMemo(() => {
    switch (fieldRule.predicate) {
      case "AfterThisDate":
        return DateMask;
      case "AfterThisDatetime":
        return DateTimeMask;
      case "AfterThisTimeOfDay":
        return TimeMask;
      case "BeforeThisDate":
        return DateMask;
      case "BeforeTodayMinusXDays":
        return undefined;
      case "BeforeNowMinusXDuration":
        return undefined;
      case "BeforeThisDatetime":
        return DateTimeMask;
      case "BeforeThisTimeOfDay":
        return TimeMask;
      case "EqualToDate":
        return DateMask;
      case "EqualToX":
        return undefined;
      case "FieldHasValue":
        return undefined;
      case "IsEmailDomain":
        return undefined;
      case "LessThanX":
        return undefined;
      case "MatchesThisString":
        return undefined;
      case "MoreThanX":
        return undefined;
      default:
        return undefined;
    }
  }, [fieldRule.predicate]);

  const valuePlaceholder = useMemo(() => {
    switch (fieldRule.predicate) {
      case "BeforeTodayMinusXDays":
        return "Number of days";
      case "BeforeNowMinusXDuration":
        return "Number of minutes";
      default:
        return undefined;
    }
  }, [fieldRule.predicate]);

  const fieldRuleStr = `templateRules.${templateRuleIdx}.fieldRules.${fieldRuleIdx}`;
  const showPredicateInput = fieldRule.templateFieldId !== undefined;
  const showValueInput =
    fieldRule.templateFieldId !== undefined &&
    fieldRule.predicate !== undefined &&
    PredicateNeedsValueSet.has(fieldRule.predicate);

  const handlePressRemove = () => {
    setRemoveModal({
      onContinue: () => arr.remove(fieldRuleIdx),
    });
  };

  const handlePressAdd = () => {
    arr.push(newFieldRule());
  };

  const styles = StyleSheet.create({
    addButton: {
      opacity: showAddButton ? 1 : 0,
    },
    fieldButtonsContainer: {
      alignItems: "flex-end",
      flexDirection: "row",
      gap: theme.spacing.m,
    },
    fieldComparisonContainer: {
      flex: 1,
    },
    fieldContainer: {
      flexDirection: "row",
      gap: theme.spacing.m,
    },
    fieldInputsContainer: {
      alignItems: "flex-end",
      flex: 1,
      flexDirection: "row",
      gap: theme.spacing.m,
      justifyContent: "space-between",
    },
    fieldNameContainer: {
      flex: 2,
    },
    fieldValueContainer: {
      flex: 2,
    },
  });

  return (
    <View key={fieldRule.id} style={styles.fieldContainer}>
      <View style={styles.fieldInputsContainer}>
        <View style={styles.fieldNameContainer}>
          <SelectField
            label=""
            name={`${fieldRuleStr}.templateFieldId`}
            onSelectOption={() => {
              ctx.setFieldValue(`${fieldRuleStr}.predicate`, undefined);
              ctx.setFieldValue(`${fieldRuleStr}.value`, undefined);
            }}
            options={fieldOptions as readonly Option[]}
            placeholder={fieldPlaceholder}
            usePortal
          />
        </View>
        <View style={styles.fieldComparisonContainer}>
          {showPredicateInput && (
            <SelectField
              key={fieldRule.templateFieldId}
              label=""
              name={`${fieldRuleStr}.predicate`}
              onSelectOption={() => {
                ctx.setFieldValue(`${fieldRuleStr}.value`, undefined);
              }}
              options={compareOptions}
              placeholder={{ label: "Select criteria", value: "" }}
              usePortal
            />
          )}
        </View>
        <View style={styles.fieldValueContainer}>
          {showValueInput && (
            <TextInputField
              autoFocus
              mask={valueMask}
              name={`${fieldRuleStr}.value`}
              placeholder={valuePlaceholder}
            />
          )}
        </View>
      </View>
      <View style={styles.fieldButtonsContainer}>
        <Button
          disabled={disableDeleteButton}
          iconLeft="trash"
          onPress={handlePressRemove}
          shape="circle"
          type="destructive"
        />
        <Button
          disabled={!showAddButton || numRules >= MAX_NUM_RULES}
          iconLeft="addSmallDefault"
          onPress={handlePressAdd}
          shape="circle"
          style={styles.addButton}
          type="secondary"
        />
        {removeModal !== undefined && (
          <RemoveModal
            onPressCancel={() => {
              setRemoveModal(undefined);
            }}
            onPressContinue={() => {
              removeModal.onContinue?.();
              setRemoveModal(undefined);
            }}
            type="field"
          />
        )}
      </View>
    </View>
  );
};
