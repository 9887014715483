/* tslint:disable */
/* eslint-disable */
/**
 * Wormhole
 * Wormhole is used by Orgs to ingest Records that correspond to Schemas. Orgs use Wormhole to create and manage their Schemas. To load in data, Orgs upload individual Records or Record files (CSVs, TSVs, ...) that match existing Schemas. Orgs access their Records to make use of their data, for example to issue Merits. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateIntegration,
  Errors,
  Integration,
  IntegrationLogEvent,
  UpdateIntegration,
} from '../models/index';
import {
    CreateIntegrationFromJSON,
    CreateIntegrationToJSON,
    ErrorsFromJSON,
    ErrorsToJSON,
    IntegrationFromJSON,
    IntegrationToJSON,
    IntegrationLogEventFromJSON,
    IntegrationLogEventToJSON,
    UpdateIntegrationFromJSON,
    UpdateIntegrationToJSON,
} from '../models/index';

export interface CreateIntegrationRequest {
    integrationData: CreateIntegration;
    authorization?: string;
}

export interface DeleteIntegrationRequest {
    integrationID: string;
    authorization?: string;
}

export interface GetIntegrationRequest {
    integrationID: string;
    authorization?: string;
}

export interface GetIntegrationLogsRequest {
    integrationID: string;
    authorization?: string;
    before?: string;
    after?: string;
    limit?: number;
    order?: GetIntegrationLogsOrderEnum;
    message?: GetIntegrationLogsMessageEnum;
    severity?: GetIntegrationLogsSeverityEnum;
}

export interface UpdateIntegrationRequest {
    integrationID: string;
    authorization?: string;
    integrationUpdates?: UpdateIntegration;
}

/**
 * 
 */
export class IntegrationsApi extends runtime.BaseAPI {

    /**
     * `create:integration`  Create an integration with with one of the supported external data providers: - SFTP server - Google Sheets - Jotform 
     * Create Integration
     */
    async createIntegrationRaw(requestParameters: CreateIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Integration>> {
        if (requestParameters.integrationData === null || requestParameters.integrationData === undefined) {
            throw new runtime.RequiredError('integrationData','Required parameter requestParameters.integrationData was null or undefined when calling createIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/integrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIntegrationToJSON(requestParameters.integrationData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationFromJSON(jsonValue));
    }

    /**
     * `create:integration`  Create an integration with with one of the supported external data providers: - SFTP server - Google Sheets - Jotform 
     * Create Integration
     */
    async createIntegration(requestParameters: CreateIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Integration> {
        const response = await this.createIntegrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `delete:integration`  Delete an integration. This will prevent any future data ingestion from the integration. Data previously ingested will remain on the platform, and any containers created from that data will remain intact and in their current state. 
     * Delete Integration
     */
    async deleteIntegrationRaw(requestParameters: DeleteIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.integrationID === null || requestParameters.integrationID === undefined) {
            throw new runtime.RequiredError('integrationID','Required parameter requestParameters.integrationID was null or undefined when calling deleteIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/integrations/{integrationID}`.replace(`{${"integrationID"}}`, encodeURIComponent(String(requestParameters.integrationID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * `delete:integration`  Delete an integration. This will prevent any future data ingestion from the integration. Data previously ingested will remain on the platform, and any containers created from that data will remain intact and in their current state. 
     * Delete Integration
     */
    async deleteIntegration(requestParameters: DeleteIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteIntegrationRaw(requestParameters, initOverrides);
    }

    /**
     * `read:integration`  Get details for an integration, including its current status 
     * Get Integration
     */
    async getIntegrationRaw(requestParameters: GetIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Integration>> {
        if (requestParameters.integrationID === null || requestParameters.integrationID === undefined) {
            throw new runtime.RequiredError('integrationID','Required parameter requestParameters.integrationID was null or undefined when calling getIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/integrations/{integrationID}`.replace(`{${"integrationID"}}`, encodeURIComponent(String(requestParameters.integrationID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationFromJSON(jsonValue));
    }

    /**
     * `read:integration`  Get details for an integration, including its current status 
     * Get Integration
     */
    async getIntegration(requestParameters: GetIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Integration> {
        const response = await this.getIntegrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:integration`  Get recent log events for an integration 
     * Get Integration Logs
     */
    async getIntegrationLogsRaw(requestParameters: GetIntegrationLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IntegrationLogEvent>>> {
        if (requestParameters.integrationID === null || requestParameters.integrationID === undefined) {
            throw new runtime.RequiredError('integrationID','Required parameter requestParameters.integrationID was null or undefined when calling getIntegrationLogs.');
        }

        const queryParameters: any = {};

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = requestParameters.before;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        if (requestParameters.severity !== undefined) {
            queryParameters['severity'] = requestParameters.severity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/integrations/{integrationID}/log`.replace(`{${"integrationID"}}`, encodeURIComponent(String(requestParameters.integrationID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IntegrationLogEventFromJSON));
    }

    /**
     * `read:integration`  Get recent log events for an integration 
     * Get Integration Logs
     */
    async getIntegrationLogs(requestParameters: GetIntegrationLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IntegrationLogEvent>> {
        const response = await this.getIntegrationLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:integration`  Update an existing integration 
     * Update Integration
     */
    async updateIntegrationRaw(requestParameters: UpdateIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Integration>> {
        if (requestParameters.integrationID === null || requestParameters.integrationID === undefined) {
            throw new runtime.RequiredError('integrationID','Required parameter requestParameters.integrationID was null or undefined when calling updateIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/integrations/{integrationID}`.replace(`{${"integrationID"}}`, encodeURIComponent(String(requestParameters.integrationID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIntegrationToJSON(requestParameters.integrationUpdates),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationFromJSON(jsonValue));
    }

    /**
     * `update:integration`  Update an existing integration 
     * Update Integration
     */
    async updateIntegration(requestParameters: UpdateIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Integration> {
        const response = await this.updateIntegrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetIntegrationLogsOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetIntegrationLogsOrderEnum = typeof GetIntegrationLogsOrderEnum[keyof typeof GetIntegrationLogsOrderEnum];
/**
 * @export
 */
export const GetIntegrationLogsMessageEnum = {
    ConnectionFailure: 'connection_failure',
    DbtRunFailed: 'dbt_run_failed',
    ExtractSummary: 'extract_summary',
    ExtractionSummary: 'extraction_summary',
    ForcedResyncTable: 'forced_resync_table',
    SyncEnd: 'sync_end',
    Warning: 'warning'
} as const;
export type GetIntegrationLogsMessageEnum = typeof GetIntegrationLogsMessageEnum[keyof typeof GetIntegrationLogsMessageEnum];
/**
 * @export
 */
export const GetIntegrationLogsSeverityEnum = {
    Info: 'info',
    Warning: 'warning',
    Severe: 'severe'
} as const;
export type GetIntegrationLogsSeverityEnum = typeof GetIntegrationLogsSeverityEnum[keyof typeof GetIntegrationLogsSeverityEnum];
