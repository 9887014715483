// Copyright 2024 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { CreatePolicyForm } from "./CreatePolicyForm";
import { Formik } from "formik";
import type { FC } from "react";
import type { Values } from "../types";

const fieldRuleSchema = Yup.object({
  id: Yup.string().uuid().required(),
  predicate: Yup.string().required("Predicate must be specified"),
  templateFieldId: Yup.string()
    .required("Template field must be selected")
    .uuid("Template field must be selected"),
  value: Yup.string(),
});

const templateRuleSchema = Yup.object({
  fieldCombinationType: Yup.string().oneOf(["all", "any"] as const),
  fieldRules: Yup.array().of(fieldRuleSchema),
  fieldValuesType: Yup.string().oneOf(["any", "specific"] as const),
  id: Yup.string().uuid().required(),
  templateId: Yup.string()
    .required("Template must be selected")
    .uuid("Template must be selected")
    .nullable(),
  templateStatus: Yup.string().oneOf(["any", "Active", "Inactive"] as const),
});

const formSchema = Yup.object({
  policyDescription: Yup.string()
    .optional()
    .max(300, "Policy description must be 300 characters or less"),
  policyName: Yup.string().min(3, "Policy name must be at least 3 characters"),
  policyPermissions: Yup.object().shape({
    othersUse: Yup.string().oneOf(["yes", "no"] as const),
    othersView: Yup.string().oneOf(["yes", "no"] as const),
  }),
  ruleCombinationType: Yup.string().oneOf(["all", "any"] as const),
  templateRules: Yup.array().of(templateRuleSchema),
});

const initialValues: Values = {
  policyName: "",
  policyPermissions: {
    othersUse: "yes",
    othersView: "yes",
  },
  ruleCombinationType: "all",
  templateRules: [],
};

export const CreatePolicyFormikWrapper: FC = () => (
  <Formik<Values>
    initialValues={initialValues}
    onReset={(_, helpers) => {
      helpers.resetForm({ values: initialValues });
    }}
    onSubmit={values => {
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(values, null, 2));
    }}
    validateOnBlur={false}
    validateOnChange={false}
    validateOnMount={false}
    validationSchema={formSchema}
  >
    <CreatePolicyForm />
  </Formik>
);
